import styled from '@emotion/styled'
import { Body, Heading, SeeMoreLink } from 'components'
import { isMobile } from 'react-device-detect'

const AccountSectionHeaderView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  padding: ${(props) => props.theme.layout.padding};
  padding-top: 0;
  padding-bottom: ${(props) => props.theme.layout.paddingMobile};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: ${(props) => props.theme.layout.paddingMobile};
    padding-top: 0;
  }

  p {
    line-height: 1;
  }
`

const AccountSectionTitle = styled(Heading)`
  text-align: left;
`

const AccountSectionSubtitle = styled(Body)``

const AccountSectionHeader = ({
  title,
  subtitle,
  to,
  toText = 'View All',
  style,
  className,
}: {
  title?: string
  subtitle?: string
  to?: string
  toText?: string
  style?: React.CSSProperties
  className?: string
}) => {
  return (
    <AccountSectionHeaderView style={style} className={className}>
      <AccountSectionTitle size={isMobile ? 'small' : 'main'}>
        {title}
      </AccountSectionTitle>
      {subtitle ? (
        <AccountSectionSubtitle size="xSmall">
          {subtitle}
        </AccountSectionSubtitle>
      ) : to ? (
        <SeeMoreLink text={toText} to={to} />
      ) : null}
    </AccountSectionHeaderView>
  )
}

export default AccountSectionHeader
