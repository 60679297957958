import { LoyaltyPerk, LoyaltyProgram } from '@open-tender/types'
import { formatQuantity } from '@open-tender/utils'
import { Button, LoyaltyStatus, LoyaltyThresholds } from '..'
import { useNavigate } from 'react-router-dom'
import {
  LoyaltyPointsArcProgressBar,
  LoyaltyPointsArcProgressBarView,
  LoyaltyPointsContent,
  LoyaltyPointsProgressLabelsView,
  LoyaltyPointsProgressLeftLabel,
  LoyaltyPointsProgressRightLabel,
  LoyaltyPointsRedeemButtonView,
  LoyaltyPointsView,
} from './LoyaltyPoints.styled'
import { isMobileOnly } from 'react-device-detect'

const LoyaltyPoints = ({
  program,
  perk,
  to,
  showThresholds = false,
}: {
  program: LoyaltyProgram
  perk: LoyaltyPerk
  showThresholds?: boolean
  to?: string
}) => {
  const navigate = useNavigate()
  const { spend_name } = program
  const { credit, thresholds } = perk
  const points = credit ? parseInt(credit) : 0
  const threshold = program.perk?.thresholds?.[0]?.points ?? 0
  const progress = parseInt(credit ?? '0', 10) / threshold
  const disabled = points < threshold
  return (
    <>
      <LoyaltyPointsView>
        <LoyaltyPointsContent>
          {/* <LoyaltyHeader title={name} subtitle={description} /> */}
          <LoyaltyPointsArcProgressBarView>
            <LoyaltyPointsArcProgressBar progress={progress} barWidth={22} />
            <LoyaltyPointsProgressLabelsView>
              <LoyaltyPointsProgressLeftLabel size="main" color="tertiary">
                Start
              </LoyaltyPointsProgressLeftLabel>
              <LoyaltyPointsProgressRightLabel size="main" color="tertiary">
                {program.perk?.thresholds?.[0]?.points ?? 0}
              </LoyaltyPointsProgressRightLabel>
            </LoyaltyPointsProgressLabelsView>
          </LoyaltyPointsArcProgressBarView>
          {spend_name && (
            <LoyaltyStatus
              count={formatQuantity(`${points}`)}
              name={`${spend_name} available`}
            />
          )}
          {!disabled && isMobileOnly && (
            <LoyaltyPointsRedeemButtonView>
              <Button
                disabled={disabled}
                size="small"
                onClick={() => navigate('/points-shop')}
              >
                Redeem
              </Button>
            </LoyaltyPointsRedeemButtonView>
          )}
        </LoyaltyPointsContent>
      </LoyaltyPointsView>

      {spend_name && thresholds && showThresholds && (
        <LoyaltyThresholds name={spend_name} thresholds={thresholds} />
      )}
    </>
  )
}

export default LoyaltyPoints
