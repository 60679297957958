import styled from '@emotion/styled'
import { BgImage, Box } from 'components'
import Heading from 'components/Heading'
import { HOVER_BOX_SHADOW } from 'utils/constants'

export const PointsShopRewardButton = styled.button<{ disabled: boolean }>`
  display: block;
  // opacity: ${(props) => (props.disabled ? '1' : '1')};
  width: 100%;
  height: 100%;
  &:hover > div {
    box-shadow: ${HOVER_BOX_SHADOW};
  }
  &:disabled {
    opacity: 1;
  }
`

export const PointsShopRewardView = styled(Box)`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
`

export const PointsShopRewardImage = styled(BgImage)`
  flex: 1;
  width: 100%;
  background-color: ${(props) => props.theme.bgColors.tertiary};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    min-height: 120px;
  }
`

export const PointsShopRewardContent = styled.div`
  flex: 0 0 auto;
  padding: 1rem 1.2rem 1.2rem;
`

export const PointsShopRewardPurchase = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.7rem 0 0;
`

export const PointsShopRewardLocked = styled.div`
  position: absolute;
  z-index: 1;
  inset: 0;
  background-color: ${(props) => props.theme.overlay.dark};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.light};
`

export const PointsShopRewardLockedText = styled(Heading)`
  color: ${(props) => props.theme.colors.light};
`
