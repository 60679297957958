import styled from '@emotion/styled'
// import { Theme, ThemeButtonColors } from '@open-tender/types'

// TODO: this is temporary mapping and should be removed when shadows are available on the theme level
// const buttonShadow: Partial<
//   Record<keyof ThemeButtonColors, (theme: Theme) => string | undefined>
// > = {
//   primary: (theme) => `-7px 7px 0px ${theme.buttons.colors.cart.bgColor}`,
//   secondary: (theme) => `-7px 7px 0px ${theme.buttons.colors.cart.bgColor}`,
// }

function reverseColorName(name: string) {
  return name.endsWith('Hover') ? name.replace('Hover', '') : `${name}Hover`
}

export const ButtonStyledView = styled.button<{
  disabled?: boolean
  size: string
  color: string
}>`
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  text-align: center;
  margin: 0;
  opacity: ${(props) => (props.disabled ? '0.5' : '1.0')};
  transition: ${(props) => props.theme.links.transition};
  font-family: ${(props) => props.theme.buttons.sizes[props.size].family};
  font-weight: ${(props) => props.theme.buttons.sizes[props.size].weight};
  font-style: ${(props) => props.theme.buttons.sizes[props.size].fontStyle};
  -webkit-font-smoothing: ${(props) =>
    props.theme.buttons.sizes[props.size].fontSmoothing};
  letter-spacing: ${(props) =>
    props.theme.buttons.sizes[props.size].letterSpacing};
  text-transform: ${(props) =>
    props.theme.buttons.sizes[props.size].textTransform};
  font-size: ${(props) => props.theme.buttons.sizes[props.size].fontSize};
  padding: ${(props) => props.theme.buttons.sizes[props.size].padding};
  border-style: solid;
  border-width: ${(props) => props.theme.buttons.sizes[props.size].borderWidth};
  border-radius: ${(props) =>
    props.theme.buttons.sizes[props.size].borderRadius};
  color: ${(props) => props.theme.buttons.colors[props.color].color};
  background-color: ${(props) =>
    props.theme.buttons.colors[props.color].bgColor};
  border-color: ${(props) =>
    props.theme.buttons.colors[props.color].borderColor};

  &:hover {
    color: ${(props) =>
      props.theme.buttons.colors[reverseColorName(props.color)].color};
    background-color: ${(props) =>
      props.theme.buttons.colors[reverseColorName(props.color)].bgColor};
    border-color: ${(props) =>
      props.theme.buttons.colors[reverseColorName(props.color)].borderColor};

    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      color: ${(props) => props.theme.buttons.colors[props.color].color};
      background-color: ${(props) =>
        props.theme.buttons.colors[props.color].bgColor};
      border-color: ${(props) =>
        props.theme.buttons.colors[props.color].borderColor};
    }
  }

  &:disabled {
    color: ${(props) => props.theme.buttons.colors[props.color].color};
    background-color: ${(props) =>
      props.theme.buttons.colors[props.color].bgColor};
    border-color: ${(props) =>
      props.theme.buttons.colors[props.color].borderColor};
  }

  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    // line-height: 1.2;
  }
`

const ButtonStyledIcon = styled.span<{ isSmall?: boolean }>`
  display: block;
  flex-shrink: 0;
  line-height: 0;
  margin-right: ${(props) => (props.isSmall ? '0.6rem' : '0.8rem')};
`

const Button = ({
  label,
  children,
  disabled,
  onClick,
  icon,
  type = 'button',
  size = 'default',
  color = 'primary',
  style,
  btnRef = undefined,
  id = undefined,
}: {
  label?: string
  icon?: JSX.Element
  children?: React.ReactNode
  disabled?: boolean
  onClick?: () => void
  size?: string
  color?: string
  type?: 'button' | 'submit' | 'reset'
  id?: string
  style?: React.CSSProperties
  btnRef?: React.LegacyRef<HTMLButtonElement>
}) => {
  const handleClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    evt.stopPropagation()
    if (!disabled && onClick) onClick()
  }

  return (
    <ButtonStyledView
      ref={btnRef}
      type={type}
      id={id}
      aria-label={label || undefined}
      onClick={(evt) => (type === 'submit' ? null : handleClick(evt))}
      disabled={disabled}
      size={size}
      color={color}
      style={style}
    >
      <span>
        {icon && (
          <ButtonStyledIcon isSmall={['header', 'small'].includes(size)}>
            {icon}
          </ButtonStyledIcon>
        )}
        {children}
      </span>
    </ButtonStyledView>
  )
}

export default Button
