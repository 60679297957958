import { useCallback, useMemo, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { CheckoutTender, Money } from '@open-tender/types'
import { checkAmountRemaining, updateTenders } from '@open-tender/utils'
import {
  selectCheckout,
  selectContent,
  selectCustomer,
  updateForm,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { FormError } from 'components'
import CheckoutSection from './CheckoutSection'
import CheckoutCreditCards from './CheckoutCreditCards'
import CheckoutHouseAccounts from './CheckoutHouseAccounts'
import CheckoutGuestCreditCard from './CheckoutGuestCreditCard'
import CheckoutPay from './CheckoutPay'
import CheckoutCreditCardAdd from './CheckoutCreditCardAdd'

const CheckoutTendersView = styled.div`
  margin: 1.5rem 0 0;
`

const CheckoutTendersErrors = styled.div`
  margin: 0 0 1.5rem;
`

const makeTenderErrors = (
  errors: Record<string, string | Record<string, string>>
) => {
  if (!errors) return []
  const tenderErrors = errors ? Object.values(errors) : []
  return tenderErrors.reduce((arr: string[], i) => {
    return typeof i === 'string'
      ? arr.concat([i])
      : arr.concat(Object.values(i as Record<string, string>))
  }, [])
}

const CheckoutTenders = () => {
  const dispatch = useAppDispatch()
  const [hasTender, setHasTender] = useState(false)
  const { checkout: config } = useAppSelector(selectContent) || {}
  const { auth } = useAppSelector(selectCustomer)
  const { check, form, errors } = useAppSelector(selectCheckout)
  const total = check?.totals ? check.totals.total : '0.00'
  const deposit = check?.deposit ? check.deposit : null
  const amountDue = deposit || total
  const amount = checkAmountRemaining(amountDue, form.tenders).toFixed(2)
  const isPaid = amount === '0.00'
  const tenderErrors = makeTenderErrors(
    errors.tenders as Record<string, string | Record<string, string>>
  )
  const hasErrors = tenderErrors.length > 0
  const giftTenders = useMemo(
    () => form.tenders.filter((i) => i.tender_type === 'GIFT_CARD'),
    [form.tenders]
  )
  const cards = useMemo(
    () => (check?.customer ? check.customer.credit_cards : []),
    [check?.customer]
  )
  const hasCards = cards && cards.length > 0
  const noTender = form.tenders.length === 0
  const customerCard = useMemo(
    () => (hasCards && noTender ? cards[0] : null),
    [hasCards, noTender, cards]
  )

  // useEffect(() => {
  //   return () => dispatch(updateForm({ tenders: [] }))
  // }, [dispatch])

  useEffect(() => {
    const tenders = updateTenders(form.tenders, amountDue as Money)
    if (tenders) {
      dispatch(updateForm({ tenders }))
    }
  }, [form.tenders, amountDue, dispatch])

  // automatically apply first credit card if user has one
  useEffect(() => {
    setHasTender(true)
    if (customerCard && !hasTender) {
      const tender = { tender_type: 'CREDIT', amount, ...customerCard }
      dispatch(updateForm({ tenders: [tender] }))
    }
  }, [hasTender, customerCard, amount, dispatch])

  const apply = useCallback(
    (data: Omit<CheckoutTender, 'tender_type' | 'amount'>, swap?: boolean) => {
      if (parseFloat(amount) > 0 || swap) {
        const tender = { tender_type: 'CREDIT', amount, ...data }
        dispatch(updateForm({ tenders: [...giftTenders, tender] }))
      }
    },
    [dispatch, amount, giftTenders]
  )

  const remove = () => {
    dispatch(updateForm({ tenders: giftTenders }))
  }

  return (
    <CheckoutSection title={config?.tenders.title}>
      <CheckoutTendersView>
        {hasErrors && (
          <CheckoutTendersErrors>
            {tenderErrors.map((errMsg, index) => (
              <FormError key={`${index}-${errMsg}`} errMsg={errMsg} />
            ))}
          </CheckoutTendersErrors>
        )}
        <CheckoutPay />
        {auth ? (
          <>
            <CheckoutCreditCards
              apply={apply}
              remove={remove}
              isPaid={isPaid}
            />
            <CheckoutCreditCardAdd isPaid={isPaid} />
            <CheckoutHouseAccounts
              apply={apply}
              remove={remove}
              isPaid={isPaid}
            />
          </>
        ) : (
          <CheckoutGuestCreditCard />
        )}
      </CheckoutTendersView>
    </CheckoutSection>
  )
}

export default CheckoutTenders
