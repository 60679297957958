import styled from '@emotion/styled'
import BgImage from 'components/BgImage'
import Body from 'components/Body'
import Heading from 'components/Heading'

export const MenuItemSizeView = styled.div`
  padding: 2rem ${(props) => props.theme.item.desktop.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 2rem ${(props) => props.theme.item.mobile.padding};
  }
`

export const MenuItemSizeToggles = styled.div`
  padding: 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 0;
  }

  button {
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }
`

export const MenuItemSizeTogglesSpacer = styled.span`
  padding: 0 1rem;
  font-size: ${(props) => props.theme.fonts.sizes.small};
`

export const MenuItemSizeButtonsHeader = styled.div`
  display: flex;
  margin: 0 0 1rem;
  justify-content: space-between;
  align-items: flex-end;
`

export const MenuItemSizeButtonsHeaderTitle = styled(Heading)`
  margin-bottom: 0.5rem;
  color: ${(props) => props.theme.colors.dark};
  font-size: ${(props) => props.theme.fonts.sizes.big};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fonts.sizes.main};
  }
  span {
    color: ${(props) => props.theme.colors.secondary};
  }
`

export const MenuItemSizeButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: ${(props) => props.theme.modifiers.box.gap};
  padding: 0 0 2rem;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-direction: column;
    gap: ${(props) => props.theme.modifiers.box.gapMobile};
  }
`

export const MenuItemSizeSectionView = styled.div`
  padding: 2rem 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 2rem 0 0;
  }
`

export const MenuItemSizeButtonView = styled.button<{
  isApplied?: boolean
  disabled?: boolean
}>`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: ${(props) => props.theme.modifiers.box.minHeight};
  border-radius: ${(props) => props.theme.modifiers.box.borderRadius};
  background-color: ${(props) => props.theme.modifiers.colors.bgColor};
  box-shadow: ${(props) => props.theme.modifiers.box.boxShadow};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${(props) => props.theme.modifiers.box.paddingMobile};
    border-width: ${(props) => props.theme.modifiers.box.borderMobile};
  }

  &:disabled {
    opacity: 1;
  }

  &.show-hover:not(.selected):hover,
  &.show-hover:not(.selected):active {
    border-color: ${(props) => props.theme.modifiers.hoverColors.borderColor};
    background-color: ${(props) => props.theme.modifiers.hoverColors.bgColor};
  }

  & > span {
    display: block;
    text-align: center;
  }
`

export const MenuItemSizeButtonCheckmark = styled.div`
  position: absolute;
  z-index: 2;
  top: -0.8rem;
  right: 0rem;
`

export const MenuItemSizeButtonImage = styled(BgImage)<{ isApplied?: boolean }>`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  width: 6rem;
  min-height: 6rem;
  margin: 0 0 1rem;
  border-style: solid;
  background-size: ${(props) => props.theme.modifiers.image.bgSize};
  box-shadow: ${(props) => props.theme.modifiers.image.boxShadow};
  border-width: ${(props) => (props.isApplied ? '2px' : '0px')};
  border-color: ${(props) => props.theme.colors.tertiary};
  background-color: ${(props) =>
    props.isApplied ? props.theme.bgColors.tertiary : 'white'};
  border-radius: 50%;
  background-position-y: -2px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-grow: 0;
    flex-shrink: 0;
    width: ${(props) => props.theme.modifiers.image.width};
    min-height: ${(props) => props.theme.modifiers.image.height};
    aspect-ratio: auto;
    margin: ${(props) => props.theme.modifiers.image.margin};
  }
`

export const MenuItemSizeButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

export const MenuItemSizeButtonInfo = styled.div`
  text-align: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    text-align: left;
  }

  & > span {
    display: block;
    text-align: center;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      text-align: left;
    }
  }
`

export const MenuItemSizeButtonTitle = styled(Body)<{ isApplied?: boolean }>`
  transition: ${(props) => props.theme.modifiers.box.transition};
  font-size: ${(props) => props.theme.modifiers.title.fontSize};
  color: ${(props) => props.theme.modifiers.title.color};
  font-weight: 700;

  button.show-hover:not(.selected):hover & {
    color: ${(props) => props.theme.modifiers.title.hoverColor};
  }

  button.selected & {
    ##color: ${(props) => props.theme.modifiers.title.selectedColor};
  }
`

export const MenuItemSizeButtonSubtitle = styled(Body)`
  margin: 0.4rem 0 0;
  transition: ${(props) => props.theme.modifiers.box.transition};
  font-size: ${(props) => props.theme.modifiers.text.fontSize};
  color: ${(props) => props.theme.modifiers.text.color};

  button.show-hover:not(.selected):hover & {
    color: ${(props) => props.theme.modifiers.title.hoverColor};
  }

  button.selected & {
    ##color: ${(props) => props.theme.modifiers.text.selectedColor};
  }
`

export const MenuItemSizeButtonPrice = styled.span`
  margin: 0.5rem 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0;
  }
  button.selected & span {
    color: ${(props) => props.theme.modifiers.text.color};
  }
`
