import styled from '@emotion/styled'
import { LoyaltyHeader, QRCode as QRCodeImage } from 'components'
import ModalView from 'components/Modal/ModalView'
import ModalContent from 'components/Modal/ModalContent'
import LoyaltyHowItWorks from './LoyaltyHowItWorks'
import {
  selectCustomerLoyaltyProgram,
  useAppSelector,
} from '@open-tender/cloud'

const QRCodeModalView = styled(ModalView)`
  max-height: 95%;
`

const QRCodeView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  text-align: center;
`

const QRCodeContent = styled.div`
  margin: 0 0 1.5rem;

  & > div {
    width: 40rem;
    margin: 2rem auto;
  }

  p {
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }
`

const QRCodeModalContent = styled(ModalContent)``

export interface QRCodeModalProps extends Record<string, unknown> {
  src: string
  alt: string
}

const QRCode = ({ src, alt }: QRCodeModalProps) => {
  const { program } = useAppSelector(selectCustomerLoyaltyProgram)
  const { name } = program || {}

  return (
    <QRCodeModalView>
      <QRCodeModalContent>
        {name && <LoyaltyHeader title={name} />}
        <QRCodeView>
          <QRCodeContent>
            <QRCodeImage src={src} title={alt} />
          </QRCodeContent>
        </QRCodeView>
        {/* <AccountLoyaltyProgram /> */}
        <LoyaltyHowItWorks />
      </QRCodeModalContent>
    </QRCodeModalView>
  )
}

export default QRCode
