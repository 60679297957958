import { useEffect } from 'react'
import {
  fetchCustomerLoyalty,
  selectCustomerLoyaltyProgram,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Loading, LoyaltyPoints, LoyaltySpend, LoyaltyVisits } from 'components'
import styled from '@emotion/styled'

const AccountLoyaltyProgramView = styled.div`
  flex: 0 0 36rem;
  border-radius: ${(props) => props.theme.border.radius};
  background-color: ${(props) => props.theme.bgColors.primary};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex: 0 0 auto;
    margin: 0 ${(props) => props.theme.layout.paddingMobile};
  }
`

const AccountLoyaltyProgram = () => {
  const dispatch = useAppDispatch()
  const { program, loading } = useAppSelector(selectCustomerLoyaltyProgram)
  const { perk } = program || {}

  useEffect(() => {
    dispatch(fetchCustomerLoyalty())
  }, [dispatch])

  if (!program && loading === 'pending') {
    return <Loading text="Retrieving your loyalty status..." />
  }

  if (!program || !perk) return null

  return (
    <AccountLoyaltyProgramView>
      {perk.loyalty_perk === 'BANKABLE_POINTS' ? (
        <LoyaltyPoints program={program} perk={perk} to="/loyalty" />
      ) : perk.loyalty_perk === 'DOLLARS_CREDIT' ? (
        <LoyaltySpend program={program} perk={perk} to="/loyalty" />
      ) : (
        <LoyaltyVisits program={program} perk={perk} to="/loyalty" />
      )}
    </AccountLoyaltyProgramView>
  )
}

export default AccountLoyaltyProgram
